/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:   tabs-manual.js
 *
 *   Desc:   Tablist widget that implements ARIA Authoring Practices
 */

"use strict";

class TabsManual {
  constructor(groupNode, isDesktop) {
    this.tablistNode = groupNode;

    this.tabs = [];

    this.firstTab = null;
    this.lastTab = null;

    this.tabs = Array.from(this.tablistNode.querySelectorAll("[role=tab]"));
    this.tabpanels = [];

    for (var i = 0; i < this.tabs?.length; i += 1) {
      const tab = this.tabs[i];
      const tabpanel = document.getElementById(
        tab.getAttribute("aria-controls")
      );

      tab.tabIndex = -1;
      tab.setAttribute("aria-selected", "false");
      this.tabpanels.push(tabpanel);

      tab.addEventListener("keydown", this.onKeydown.bind(this));
      tab.addEventListener("click", (e) => {
        this.onClick(e, isDesktop);
      });

      if (!this.firstTab) {
        this.firstTab = tab;
      }
      this.lastTab = tab;
    }

    this.setSelectedTab(this.firstTab);
  }

  setSelectedTab(currentTab) {
    for (var i = 0; i < this.tabs?.length; i += 1) {
      const tab = this.tabs[i];
      if (currentTab.getAttribute("id") === tab.getAttribute("id")) {
        tab.setAttribute("aria-selected", "true");
        tab.removeAttribute("tabindex");
        tab.parentElement.classList.add("is-selected");
        this.tabpanels[i].classList.remove("is-hidden");
        this.tabpanels[i].classList.add("tab-panel-active");
      } else {
        tab.setAttribute("aria-selected", "false");
        tab.tabIndex = -1;
        tab.parentElement.classList.remove("is-selected");
        this.tabpanels[i].classList.add("is-hidden");
        this.tabpanels[i].classList.remove("tab-panel-active");
      }
    }
  }

  moveFocusToTab(currentTab) {
    currentTab.focus();
  }

  moveFocusToPreviousTab(currentTab) {
    let index;

    if (currentTab === this.firstTab) {
      this.moveFocusToTab(this.lastTab);
    } else {
      index = this.tabs.indexOf(currentTab);
      this.moveFocusToTab(this.tabs[index - 1]);
    }
  }

  moveFocusToNextTab(currentTab) {
    let index;

    if (currentTab === this.lastTab) {
      this.moveFocusToTab(this.firstTab);
    } else {
      index = this.tabs.indexOf(currentTab);
      this.moveFocusToTab(this.tabs[index + 1]);
    }
  }

  onKeydown(event) {
    const tgt = event.currentTarget;
    let flag = false;

    switch (event.key) {
      case "ArrowLeft":
        this.moveFocusToPreviousTab(tgt);
        flag = true;
        break;

      case "ArrowRight":
        this.moveFocusToNextTab(tgt);
        flag = true;
        break;

      case "Home":
        this.moveFocusToTab(this.firstTab);
        flag = true;
        break;

      case "End":
        this.moveFocusToTab(this.lastTab);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onClick(event, isDesktop) {
    this.setSelectedTab(event.currentTarget);
    this.firstTab.scrollIntoView({ behavior: "smooth" });
    const currentTabpanel = document.querySelector(
      `#${event.currentTarget?.getAttribute("aria-controls")}`
    );
    const stickyBarHeight = isDesktop ? 90 : 40;
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          currentTabpanel.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          stickyBarHeight,
      });
    }, 200);
  }
}

export default TabsManual;
